<script setup lang="ts">
import { VChip } from "vuetify/components/VChip";
import type { RouteLocationRaw } from "vue-router";

const emit = defineEmits<{
  (e: "click:close", v: unknown): void;
}>();

const props = defineProps<{
  to?: unknown;
  color?: string;
  size?: "x-small" | "small" | "default" | "large" | "x-large";
  closable?: boolean;
  variant?: "flat" | "text" | "outlined" | "plain" | "elevated" | "tonal";
  label?: boolean;
  disabled?: boolean;
  clearable?: boolean;
}>();
</script>

<template>
  <VChip
    v-bind="{
      ...props,
      to: to as RouteLocationRaw,
      variant: props.variant ?? 'flat',
    }"
    @click:close="(v) => emit('click:close', v)"
  >
    <slot />
  </VChip>
</template>
